import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Seo from '../../../components/seo';
import Header from '../../../components/Header';
import Footer from '../../../components/footer';
import Blocks from '../../../components/blocks';
import Layout from '../../../components/layout';
import '../../../sass/main.scss';
// import { setOmCode, setPhoneLocalStorage, setCookieTestUser, getCookieTestUser, gtmTrackingDetailDataLayer } from '../../../components/utilities';
import axios from 'axios';
import PageLoadingData from '../../../components/BlockLibrary/Common/PageLoadingData';
const contentful = require('contentful');

const client = contentful.createClient({
     space: process.env.CONTENTFUL_SPACE_ID,
     accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
     environment: process.env.GATSBY_CONTENTFUL_PREVIEW_ENVIRONMENT
});

class SearchResult extends React.Component {
     constructor(props) {
          super(props);

          this.state = {
               loading: true
          };
     }
     componentDidMount() {
          const page = get(this.props, 'data.contentfulPage');

          client
               .getEntries({
                    content_type: 'employerGroups',
                    'fields.searchable[in]': true,
                    'fields.groupType': 'Employer', // Get entries that have value for Group Type field
                    'fields.groupDisplayName[exists]': true,
                    order: 'fields.groupDisplayName',
                    limit: 0
               })
               .then(async (response) => {
                    let newGroups = [];
                    for (let i = 0; i <= response.total / 1000; i++) {
                         const getEntries = client.getEntries({
                              content_type: 'employerGroups',
                              'fields.searchable[in]': true,
                              'fields.groupType': 'Employer', // Get entries that have value for Group Type field
                              'fields.groupDisplayName[exists]': true,
                              order: 'fields.groupDisplayName',
                              limit: 1000,
                              skip: 1000 * i
                         });
                         newGroups.push(getEntries);
                    }

                    Promise.all(newGroups)
                         .then((data) => {
                              let newEmployerGroup = [];
                              data.forEach(({ items }) => {
                                   let newEmployerGroups = items.map(({ fields: group }) => ({
                                        node: group
                                   }));
                                   newEmployerGroup = newEmployerGroup.concat(newEmployerGroups);
                              });
                              this.props.setEmployerGroupsData(newEmployerGroup);
                              this.setState({ loading: false });
                         })
                         .catch(console.error);
               })
               .catch(console.error);

          const generalSettings = get(this.props, 'data.contentfulGeneralSettings');
          this.props.setIncludeNationwideContent(generalSettings.includeNationwideContent);

          client
               .getEntries({
                    content_type: 'generalSettings',
                    'fields.includeNationwideContent[in]': true
               })
               .then((response) => {
                    if (response.items.length) {
                         const query = `query($searchable: Boolean, $partnerTypeValues: [String]) {
                              petHealthInsuranceSingleProductAutomationCmCollection(where: { searchable: $searchable, partnerType_in: $partnerTypeValues }) {
                                   items {
                                        sys {
                                             id
                                        }
                                        spid
                                        thirdpartyId
                                        partnerType
                                        partnershipName
                                   }
                              }
                              dualProductAutomationCmCollection(where: { searchable: $searchable, partnerType_in: $partnerTypeValues }) {
                                   items {
                                        sys {
                                             id
                                        }
                                        spid
                                        thirdpartyId
                                        partnerType
                                        partnershipName
                                   }
                              }
                              vetHelpLineSingleProductAutomationCmCollection(where: { searchable: $searchable, partnerType_in: $partnerTypeValues }) {
                                   items {
                                        sys {
                                             id
                                        }
                                        spid
                                        thirdpartyId
                                        partnerType
                                        partnershipName
                                   }
                              }
                         }`;
                         const dataSearch = {
                              query,
                              variables: '{\r\n  "searchable":true,\r\n  "partnerTypeValues":["Voluntary Benefits", "Strategic", "Member Specialty Group"]\r\n}'
                         };
                         axios.post(
                              `https://graphql.contentful.com/content/v1/spaces/${process.env.EP_CONTENTFUL_SPACE_ID}/environments/${process.env.EP_CONTENTFUL_ENVIRONMENT}`,
                              dataSearch,
                              {
                                   headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${process.env.EP_CONTENTFUL_ACCESS_TOKEN}`
                                   }
                              }
                         )
                              .then((data) => {
                                   const petHealthInsurance = data.data.data.petHealthInsuranceSingleProductAutomationCmCollection?.items || [];
                                   const dualProduct = data.data.data.dualProductAutomationCmCollection?.items || [];
                                   const vetHelpLine = data.data.data.vetHelpLineSingleProductAutomationCmCollection?.items || [];
                                   const partnerships = [...petHealthInsurance, ...dualProduct, ...vetHelpLine];
                                   const partnershipsConvert = partnerships.map((item) => {
                                        return {
                                             node: {
                                                  groupType: item.partnerType,
                                                  groupDisplayName: item.partnershipName,
                                                  slug: item.thirdpartyId,
                                                  legend: 'EP Asset'
                                             }
                                        };
                                   });
                                   this.props.setPartnerships(partnershipsConvert);
                              })
                              .catch((error) => {
                                   console.log('error => ', error);
                              });
                    }
               })
               .catch(console.error);

          // const brower = typeof window !== `undefined`;

          // if (brower) {
          //      setOmCode(page.trackingCode, this.props.location.href);
          //      setPhoneLocalStorage(page.specificPhone, this.props.location.href);

          //      var getOmValue = localStorage.getItem('Petinsurance_OM');
          //      this.props.setTrackingCode(getOmValue);

          //      var getPhoneValue = localStorage.getItem('Petinsurance_TrackingPhone');
          //      this.props.setTrackingPhone(getPhoneValue);

          //      setCookieTestUser(this.props.location.href);
          //      const pageName = page.omniturePageNameLegacy || page.contentName;
          //      const testUser = getCookieTestUser('testUser') != '' ? getCookieTestUser('testUser') : 'No';
          //      const originCode = getOmValue ? getOmValue.substr(0, 2) : '';
          //      const trackingCodeA = getOmValue ? getOmValue.substr(2) : '';

          //      gtmTrackingDetailDataLayer('prePageview', {
          //           pageName: pageName,
          //           testUser: testUser,
          //           omCode: getOmValue,
          //           origin: originCode,
          //           trackingCode: trackingCodeA,
          //           campaignPhone: getPhoneValue
          //      });
          // }
     }

     render() {
          const page = get(this.props, 'data.contentfulPage');

          return (
               <Layout>
                    <Seo
                         pageName={page.omniturePageNameLegacy || page.contentName}
                         specificPhone={page.specificPhone}
                         trackingCode={page.trackingCode}
                         title={page.seoTitleTag}
                         path=""
                         canonical={page.seoCanonicalUrl ? page.seoCanonicalUrl.seoCanonicalUrl : ''}
                         description={page.seoMetaDescription ? page.seoMetaDescription.seoMetaDescription : ''}
                         robots={page.robotsDirective ? page.robotsDirective.robotsDirective : ''}
                         image={page.socialImage}
                         googleSiteVerificationContent={page.googleSiteVerificationContent}
                         showAntiFlickerSnippet={page.showAntiFlickerSnippet}
                    />
                    <Header header={page.header} specificPhone={page.specificPhone}></Header>
                    <main id="main-content" role="main">
                         {!this.state.loading ? <Blocks blocks={page.rows}></Blocks> : <PageLoadingData />}
                    </main>

                    <Footer footer={page.footer} specificPhone={page.specificPhone}></Footer>
               </Layout>
          );
     }
}

const mapDispatchToProps = (dispatch) => {
     return {
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone }),
          setEmployerGroupsData: (data) => dispatch({ type: 'SET_EMPLOYER_GROUPS_DATA', payload: data }),
          setIncludeNationwideContent: (data) => dispatch({ type: 'SET_INCLUDE_NATIONWIDE_CONTENT', payload: data }),
          setPartnerships: (data) => dispatch({ type: 'SET_PARTNERSHIPS_DATA', payload: data })
     };
};

const mapStateToProps = (state) => {
     return {
          state
     };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);

export const pageQuery = graphql`
     query SearchResultBySlug {
          site {
               siteMetadata {
                    title
               }
          }
          contentfulPage(slug: { eq: "employee-benefits/company-search/results" }) {
               ...ContentfulPageFields
          }
          contentfulGeneralSettings {
               includeNationwideContent
          }
     }
`;
